import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('src/views/general/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.general.ecommerce,
      component: lazy(() => import('src/views/general/DashboardEcommerceView'))
    },
    {
      exact: true,
      path: PATH_APP.general.analytics,
      component: lazy(() => import('src/views/general/DashboardAnalyticsView'))
    },
    {
      exact: true,
      path: PATH_APP.general.requests,
      component: lazy(() => import('src/views/general/DashBoardRequestView'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />
    },

    // MANAGEMENT : E-COMMERCE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.eCommerce.products,
      component: lazy(() => import('src/views/e-commerce/ShopView'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.product,
      component: lazy(() => import('src/views/e-commerce/ProductDetailsView'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.list,
      component: lazy(() => import('src/views/e-commerce/ProductListView'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.checkout,
      component: lazy(() => import('src/views/e-commerce/CheckoutView'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.invoice,
      component: lazy(() => import('src/views/e-commerce/InvoiceView'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.root,
      component: () => <Redirect to={PATH_APP.management.eCommerce.products} />
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.user.profile,
      component: lazy(() => import('src/views/user/ProfileView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.cards,
      component: lazy(() => import('src/views/user/UserCardsView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.list,
      component: lazy(() => import('src/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.account,
      component: lazy(() => import('src/views/user/AccountView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />
    },
    {
      exact: true,
      path: PATH_APP.management.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />
    },

    // APP : CHAT
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.chat.conversation,
      component: lazy(() => import('src/views/app/ChatView'))
    },
    {
      exact: true,
      path: PATH_APP.app.chat.root,
      component: () => <Redirect to={PATH_APP.app.chat.new} />
    },
    {
      exact: true,
      path: PATH_APP.app.mail.root,
      component: () => <Redirect to={PATH_APP.app.mail.all} />
    },
    {
      exact: true,
      path: PATH_APP.foundations.root,
      component: () => <Redirect to={PATH_APP.foundations.colors} />
    },
    {
      exact: true,
      path: PATH_APP.components.chart.root,
      component: () => <Redirect to={PATH_APP.components.chart.apexcharts} />
    },
    {
      exact: true,
      path: PATH_APP.components.map.root,
      component: () => <Redirect to={PATH_APP.components.map.google} />
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
