import jwtDecode from 'jwt-decode';
// import axios from 'src/utils/axios';
import axios from 'src/axios';
import { createSlice } from '@reduxjs/toolkit';
import firebaseConfig from 'src/config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: [],
  otp: ''
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // Request Success
    requestSuccess(state, action) {
      state.user = action.payload.user;
    },

    // UPDATE
    updateSuccess(state, action) {
      state.user = action.payload.user;
    },

    // UPDATE
    otpUpdateSuccess(state, action) {
      state.otp = action.payload.otp;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (key) => {
  if (!key) {
    return false;
  }
  const decoded = jwtDecode(key);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (key) => {
  if (key) {
    localStorage.setItem('key', key);
    axios.defaults.headers.common.Authorization = `Token ${key}`;
  } else {
    localStorage.removeItem('key');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setSessionUid = (Uid, Pid) => {
  if (Uid) {
    localStorage.setItem('Uid', Uid);
  } else {
    localStorage.removeItem('Uid');
  }
  if (Pid) {
    localStorage.setItem('Pid', Pid);
  } else {
    localStorage.removeItem('Pid');
  }
};

// ----------------------------------------------------------------------

export function login({ email, password }) {
  return async (dispatch) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let username = email;
    if (re.test(email)) {
      const response = await axios.post('/rest/auth/login/', {
        email,
        password
      });
      const { key } = response.data;
      setSession(key);
      const response1 = await axios.get('/rest/auth/user/');
      const user = response1.data;
      setSessionUid(user.id, user.pid);
      dispatch(slice.actions.loginSuccess({ user }));
    } else {
      const response = await axios.post('/rest/auth/login/', {
        username,
        password
      });
      const { key } = response.data;
      setSession(key);
      const response1 = await axios.get('/rest/auth/user/');
      const user = response1.data;
      setSessionUid(user.id, user.pid);
      dispatch(slice.actions.loginSuccess({ user }));
    }
  };
}

// ----------------------------------------------------------------------

export function register({ email, username, password1, password2 }) {
  return async (dispatch) => {
    const response = await axios.post('/rest/auth/registration/', {
      username,
      email,
      password1,
      password2
    });
    const key = response.data.key;
    setSession(key);
    const response1 = await axios.get('/rest/auth/user/', {
      headers: {
        Authorization: `Token ${key}`
      }
    });
    const user = response1.data;
    setSessionUid(user.id, user.pid);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function send_request({ request_to, request_by, type }) {
  return async (dispatch) => {
    const response = await axios.post('/rest/request/', {
      request_to,
      request_by,
      type
    });
    const user = response.data;
    dispatch(slice.actions.requestSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function change_request({ request_id, is_accepted }) {
  return async (dispatch) => {
    const response = await axios.put(`/rest/request/${request_id}/`, {
      is_accepted: is_accepted,
      is_changed: 'True'
    });
    const user = response.data;
    dispatch(slice.actions.requestSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function update({
  first_name,
  last_name,
  email,
  username,
  birthdate,
  gender,
  intrest,
  city,
  bio,
  image,
  is_cat,
  is_coffee,
  is_morning
}) {
  return async (dispatch) => {
    const ui = localStorage.getItem('Uid');
    const pi = localStorage.getItem('Pid');
    try {
      const response = await axios.put('/rest/auth/user/', {
        first_name,
        last_name,
        email,
        username
      });
    } catch {}
    try {
      const response2 = await axios.put(`/rest/profile-extra/${pi}/`, {
        user: ui,
        gender,
        birthdate,
        intrest,
        bio,
        city,
        is_cat,
        is_coffee,
        is_morning
      });
    } catch {}
    try {
      let data = new FormData();
      data.append('image', image, image.name);
      data.append('user', ui);
      const response3 = await axios.put(`/rest/profile-extra/${pi}/`, data, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
      });
    } catch {}
    const u_resp = await axios.get('/rest/auth/user/');
    const user = u_resp.data;
    dispatch(slice.actions.updateSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function verifyUser() {
  return async (dispatch) => {
    const ui = localStorage.getItem('Uid');
    const pi = localStorage.getItem('Pid');
    try {
      const response = await axios.patch(`/rest/profile-extra/${pi}/`, {
        user: ui,
        is_verified: 'True'
      });
      const u_resp = await axios.get('/rest/auth/user/');
      const user = u_resp.data;
      dispatch(slice.actions.updateSuccess({ user }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendCode() {
  return async (dispatch) => {
    const ui = localStorage.getItem('Uid');
    try {
      const response = await axios.post('rest/send-code/', {
        user: ui
      });
      const otp = response.data.code;
      const u_resp = await axios.get('/rest/auth/user/');
      const user = u_resp.data;
      user.otp = response.data.code;
      dispatch(slice.actions.updateSuccess({ user }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    const response = await axios.post('/rest/auth/logout/');
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function reset_password(email) {
  return async (dispatch) => {
    const response = await axios.post('/rest/auth/password/reset/', {
      email: email.email
    });
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function change_password(password1, password2) {
  return async (dispatch) => {
    const response = await axios.post('/rest/auth/password/change/', {
      new_password1: password1.password1,
      new_password2: password2.password2
    });
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const key = window.localStorage.getItem('key');

      if (key && isValidToken(key)) {
        setSession(key);

        const response = await axios.get('/rest/auth/user/');
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
