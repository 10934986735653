export const mapConfig = {
  apiGoogle: process.env.REACT_APP_MAP_GOOGLE,
  apiMapBox: process.env.REACT_APP_MAP_MAPBOX
};

export const firebaseConfig = {
  apiKey: 'AIzaSyA3YaSxh5wDLzOHO1lr7xztfTL6KFGDmn8',
  authDomain: 'choices-b47a6.firebaseapp.com',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: 'choices-b47a6',
  storageBucket: 'choices-b47a6.appspot.com',
  messagingSenderId: '729623879658',
  appId: '1:729623879658:web:7ad44dfa9614afe78c9c93',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const googleAnalyticsConfig = 'UA-196339969-1';
