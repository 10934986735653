import axios from 'axios';

// const instance = axios.create({
//   baseURL: 'http://aamhimemekar.com:8000/'
// });

// const instance = axios.create({
//   baseURL: 'http://159.89.166.90:8000/'
// });

const instance = axios.create({
  baseURL: 'https://app.3choices.in/'
  // baseURL: 'http://127.0.0.1:8000/'
});

export default instance;
